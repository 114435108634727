import React from "react"
import { navigate } from "gatsby"
import Tile from "./tile/tile"
import * as manager from "./manager-overview.module.css"
import notification from "../images/dw-icons_Notifications.svg"
import configuration from "../images/dw-icons_Settings.svg"
import questionnaire from "../images/dw-icons_Questionnaire.svg"
import invites from "../images/dw-icons_Invitations.svg"
import history from "../images/dw-icons_ScreeningHistory.svg"
import users from "../images/dw-icons_Users.svg"
import testTube from "../images/dw-icons_testtube.svg"

const ManagerOverview = props => {
  const moveTo = (path) => () => {
    navigate(path);
  }
    let html = [];
    if(props.userContext[0].username){
      if(props.userContext[0].signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin')){
        html.push(<Tile onClick={moveTo('/manager/preferences')} imageSrc={notification} text="Notifications" key="managerNotifications" />)
        html.push(<Tile onClick={moveTo('/manager/tenant')} imageSrc={configuration} text="Tenant Configuration" key="tenantConfiguration"/>)
        html.push(<Tile onClick={moveTo('/manager/tenant-analytic-edit')} imageSrc={questionnaire} text="Questionnaire" key="questionnaireConfiguration"/>)
        html.push(<Tile onClick={moveTo('/manager/user')} imageSrc={users} text="Users" key="userList"/>)
        html.push(<Tile onClick={moveTo('/manager/invite')} imageSrc={invites} text="Invites" key="inviteList" />)
      }
      // if(props.userContext[0].signInUserSession.accessToken.payload["cognito:groups"].includes('Logistician')) {
      //   html.push(<Tile onClick={moveTo('/manager/orders')} imageSrc={questionnaire} text="Orders" key="orders" />)
      // }
      if(props.userContext[0].signInUserSession.accessToken.payload["cognito:groups"].includes('Manager') ||
        props.userContext[0].signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin')){
        // html.push(<div></div>)
        html.push(<Tile onClick={moveTo('/manager/results')} imageSrc={history} text="Screening Results" key="resultConfiguration" />)
        // html.push(<Tile onClick={moveTo('/manager/medicallookup')} imageSrc={testTube} text="Medical Test Results" key="medicalLookup" />)
        // html.push(<Tile onClick={moveTo('/smart-health-card/read')} imageSrc={testTube} text="Scan Health Cards" key="scancards" />)
        html.push(<Tile onClick={moveTo('/manager/observations')} imageSrc={testTube} text="Observations" key="observations" />)
      }
      if(html.length<=0){
        html.push(<p>User is not a manager</p>)
      }
    }
    return (
      <div className={manager.managerButtons}>
        {html}
      </div>
    )
}

export default ManagerOverview