import { Link } from "gatsby"
import React, { useEffect, useState, createContext, useContext } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ManagerOverview from "../../components/manager-overview.js"
import CustomAuth from "../../components/custom-auth"
import { Auth } from "aws-amplify"
import { withAuthenticator } from "@aws-amplify/ui-react"
import { UserContext } from "../../provider/usercontextprovider"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManagerOrAdmin = userContext.username && (
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin')
    || userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('Manager'))

  return (
        <CustomAuth>
          <SEO title="Manager" />
          { isManagerOrAdmin ? 
            <UserContext.Consumer>
              {(userContext) => (
                <ManagerOverview userContext={userContext}/>
              )}
            </UserContext.Consumer>
          :
            <p>User is not a manager or admin</p>
          }
        </CustomAuth>
  )

}
export default ProtectedPage
// export {ProtectedPage,UserContext}