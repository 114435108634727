import React from "react"
import * as styles from "./tile.module.css"

const Tile = props => {
  return (
    <div onClick={props.onClick} style={props.style} className={styles.container}>
      <img src={props.imageSrc} /><div>{props.text}</div>
    </div>
  )
}

export default Tile
